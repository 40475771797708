import '@Components/Pages/Confirmation/styles.scss'

import React, { Fragment, useRef } from 'react'
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'

import BarHeader from '@Components/shared/Headers/BarHeader'
import { Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ReactToPrint from 'react-to-print'
import { numberWithCommas } from '@Helpers'

const Confirmation = props => {
  const {
    confirmation_number,
    reservation_date,
    buyer_name,
    email,
    phone,
    address,
    city,
    state,
    is_cobuyer,
    is_financing,
    is_inspection,
    is_warranty,
    vehicle
  } = typeof window === 'undefined' ? {} : props.location.state

  const componentRef = useRef()

  return <div className='confirmation' ref={componentRef}>
    <BarHeader text='Confirmation' />
    <Col md={{ span: 8, offset: 2 }}
      sm={{ span: 10, offset: 1 }}>
      <p className='confirmation-text-top'>Thank you! Your reservation request is being processed.</p>
      <p className='confirmation-text-bottom'>You should receive an email confirmation shortly. If you do not,
        please contact us and we will be happy to assist with your vehicle reservation</p>
      <div className='confirmation-info'>
        <div className='confirmation-info-left'>
          <div><span className='confirmation-info-title'>CONFIRMATION #:</span> {confirmation_number}</div>
          <div><span className='confirmation-info-title'>Reservation Date:</span> {reservation_date}</div>
          <div><span className='confirmation-info-title'>Name:</span> {buyer_name}</div>
          <div><span className='confirmation-info-title'>Email:</span> {email}</div>
          <div><span className='confirmation-info-title'>Phone:</span> {phone}</div>
          <div><span className='confirmation-info-title'>Address:</span>{address}</div>
          <div><span className='confirmation-info-title'>City:</span> {city}</div>
          <div><span className='confirmation-info-title'>State:</span> {state}</div>
        </div>
        <div className='confirmation-info-right'>
          <div>
            <span className='confirmation-info-right-icon'>
              {is_cobuyer
                ? <FontAwesomeIcon data-testid='cobuyer-true' icon={faCheck} />
                : <FontAwesomeIcon data-testid='cobuyer-false' icon={faTimes} />}
            </span>
            <span className='confirmation-info-right-text'>CO-BUYER</span>
          </div>
          <div>
            <span className='confirmation-info-right-icon'>
              {is_financing
                ? <FontAwesomeIcon data-testid='financing-true' icon={faCheck} />
                : <FontAwesomeIcon data-testid='financing-false' icon={faTimes} />}
            </span>
            <span className='confirmation-info-right-text'>FINANCING</span>
          </div>
          <div>
            <span className='confirmation-info-right-icon'>
              {is_inspection
                ? <FontAwesomeIcon data-testid='inspection-true' icon={faCheck} />
                : <FontAwesomeIcon data-testid='inspection-false' icon={faTimes} />}
            </span>
            <span className='confirmation-info-right-text'>INSPECTION</span>
          </div>
          <div>
            <span className='confirmation-info-right-icon'>
              {is_warranty
                ? <FontAwesomeIcon data-testid='warranty-true' icon={faCheck} />
                : <FontAwesomeIcon data-testid='warranty-false' icon={faTimes} />}
            </span>
            <span className='confirmation-info-right-text'>PROTECTION</span>
          </div>
          <div>
            <span className='confirmation-info-right-icon'>
              <FontAwesomeIcon icon={faCheck} />
            </span>
            <span className='confirmation-info-right-text'>POLICY</span>
          </div>
        </div>
      </div>
      <div className='confirmation-car'>
        {vehicle && <Fragment>
          {vehicle.glamourphotos?.length &&
            vehicle.glamourphotos[0]?.image_url?.length &&
            <img className='confirmation-car-photo' src={vehicle.glamourphotos[0].image_url} alt='vehicle-photos-1' />}
          <div>
            <div className='confirmation-car-info-title'>{vehicle.year} {vehicle.make} {vehicle.model}</div>
            <div className='confirmation-car-info-price'>${numberWithCommas(vehicle.price)}</div>
            <div className='confirmation-car-info-sub'>
              <div className='confirmation-car-info-sub-section-1'>
                MILEAGE
                      <div className='confirmation-car-info-sub-text'>{numberWithCommas(vehicle.mileage)}</div>
              </div>
              <div className='confirmation-car-info-sub-section-2'>
                VIN
                      <div className='confirmation-car-info-sub-text'>{vehicle.vin}</div>
              </div>
            </div>
          </div>
        </Fragment>}
      </div>
      <ReactToPrint
        trigger={() => <div className='print-button'>PRINT</div>}
        content={() => componentRef.current}
      />
    </Col>
  </div>
}

export default Confirmation
