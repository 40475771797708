const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
})

export const numberWithCommas = thisNumber => thisNumber.toLocaleString()

/** Pretty prints the availability time frame.
 * @param {Number} weeksTime The number of weeks before the vehicle is available
 */
export const formatWeeksTimeString = weeksTime => {
    if (weeksTime <= 0) return 'Today'
    if (!weeksTime || weeksTime > 6) return '6 weeks+'
    if (weeksTime === 1) return '1 Week'
    return `${weeksTime} Weeks`
}

export const formatAvailabilityNote = weeksTime => {
    if (weeksTime <= 0) return ''
    if (!weeksTime || weeksTime > 6) return '\u2014Due to supply chain delays, availability could be up to 20 weeks.'
    return ''
}

/** Formats a given amount to the US standard display format for dollar amounts.
 * 
 * Will round to the nearest dollar.
 * @param {Number} amount The dollar amount to format
 */
export const formatCurrency = amount => formatter.format(amount)